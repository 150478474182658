<template>
  <div id="refer-and-earn">
    <template v-if="!loading">
      <section class="intro" id="overview">
        <div class="container-fluid has-edit">
          <edit-button @click="edit_intro = true" />
          <div class="row">
            <div class="col-md-7">
              <div class="caption">
                <h1>REFER <span class="text-secondary">AND</span> EARN</h1>
                <h2 class="text-muted">{{ intro.tagline }}</h2>
                <a href="#how-it-works" class="btn btn-primary"
                  >Submit Property Details</a
                >
              </div>
            </div>
            <div class="col-md-5 desktop-only">
              <img :src="intro.image" alt="image" style="max-width: 100%" />
            </div>
          </div>
        </div>
      </section>
      <section
        class="section-area section_advantages triagl triagl-top-gray bg-gray has-edit"
      >
        <form @submit.prevent="updateOverview">
          <div class="border-wave border-wave_white"></div>
          <div class="container">
            <edit-button @click="edit_overview = true" v-if="!edit_overview" />
            <div class="row">
              <div class="col-md-4">
                <h2 class="ui-title-block">WELCOME TO OUR</h2>
                <div class="ui-subtitle-block text-uppercase">
                  Referral Program
                </div>
                <p v-if="!edit_overview">{{ overview.about }}</p>
                <textarea
                  v-if="edit_overview"
                  rows="5"
                  v-model="overview.about"
                  class="form-control"
                ></textarea>
                <template v-if="!edit_overview">
                  <div v-if="$auth.hasToken()">
                    <p>
                      Share your referral link with others and start eaning
                      every time they post a listing.
                    </p>
                    <referral-link />
                  </div>
                  <router-link
                    :to="{ name: 'register' }"
                    class="btn btn-primary"
                    v-if="!$auth.hasToken()"
                    >Register To Start Earning</router-link
                  >
                </template>
              </div>
              <div class="col-md-8">
                <ul class="advantages-list">
                  <li
                    class="advantages-list__item"
                    v-for="(feature, i) in overview.features"
                    :key="`feature-${i}`"
                  >
                    <div class="pennant">
                      <i class="icon fa fa-life-ring"></i>
                    </div>
                    <div class="advantages-list__inner">
                      <h3 class="advantages-list__title">
                        <span v-if="!edit_overview">{{ feature.title }}</span>
                        <input
                          v-if="edit_overview"
                          type="text"
                          v-model="feature.title"
                          class="form-control"
                        />
                      </h3>
                      <p class="ui-text" v-if="!edit_overview">
                        {{ feature.content }}
                      </p>
                      <p v-if="edit_overview">
                        <textarea
                          v-model="feature.content"
                          rows="2"
                          class="form-control"
                        ></textarea>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="form-group" v-if="edit_overview">
                  <button class="btn btn-primary">
                    <i class="lni lni-save"></i>Save
                  </button>
                  <a
                    href="#"
                    @click.prevent="edit_overview = false"
                    class="btn btn-warning float-right"
                    ><i class="lni lni-close"></i>Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <section
        class="section-area section_list-scheme triagl triagl-btm-gray bg-white"
        id="how-it-works"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="has-edit">
                <edit-button @click="edit_how = true" v-if="!edit_how" />
                <h2 class="ui-title-block">WANT TO START EARNING?</h2>
                <div class="ui-subtitle-block">
                  <strong>HOW WE MAKE IT HAPPEN</strong>
                </div>
                <div class="border-color border-color_center"></div>
                <form
                  @submit.prevent="updateHow"
                  class="mt-2"
                  style="margin-bottom: 70px"
                >
                  <div v-if="!edit_how" v-html="how"></div>
                  <div class="form-group" v-if="edit_how">
                    <label>Content</label>
                    <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
                      v-model="how"
                      :init="{
                        height: 350,
                        menubar: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | image',
                      }"
                    />
                  </div>
                  <div class="form-group text-left" v-if="edit_how">
                    <button class="btn btn-primary">
                      <i class="lni lni-save"></i>Save
                    </button>
                    <a
                      href="#"
                      @click.prevent="edit_how = false"
                      class="btn btn-warning float-right"
                      >Cancel</a
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-5" v-if="$auth.hasToken()">
              <div class="card text-left" v-if="!submitting_form">
                <h4>Fill The Form Below</h4>
                <hr />
                <form @submit.prevent="submit">
                  <div class="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Listing Description"
                      v-model="listing.description"
                    />
                    <span class="help-text"
                      >Description on the listing notice.</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      v-model="listing.phone_number"
                    />
                    <span class="help-text">Phone Number on the notice.</span>
                  </div>
                  <div class="form-group">
                    <label>Region</label>
                    <select-region v-model="listing.region_id" />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary">
                      <i class="lni lni-save"></i>Save
                    </button>
                  </div>
                </form>
              </div>
              <loading v-if="submitting_form" class="card" />
            </div>
            <div class="col-md-5" v-if="!$auth.hasToken()">
              <div class="card">
                <h3>Register to begin</h3>
                <hr />
                <register @success="registered" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="section-area section-default section-reviews-2 bg-gray"
        id="testimonials"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <form @submit.prevent="updateInterested">
                <section class="section_mod-2 has-edit">
                  <edit-button
                    @click="edit_interested = true"
                    v-if="!edit_interested"
                  />
                  <h2 class="ui-title-block">INTERESTED?</h2>
                  <div class="ui-subtitle-block">HOW WE CAN HELP YOU</div>
                  <div class="border-color border-color_default"></div>
                  <p v-if="!edit_interested">{{ interested.content }}</p>
                  <textarea
                    v-if="edit_interested"
                    rows="4"
                    v-model="interested.content"
                    class="form-control"
                  ></textarea>
                  <ul class="list-mark list-mark_small">
                    <li
                      class="list-mark__item"
                      v-for="(feature, i) in interested.features"
                      :key="`interested-${i}`"
                    >
                      <a
                        v-if="!edit_interested"
                        href="javascript:void(0);"
                        class="list-mark__link"
                        ><i class="icon"></i>{{ feature }}</a
                      >
                      <input
                        v-if="edit_interested"
                        type="text"
                        v-model="interested.features[i]"
                        class="form-control"
                      />
                    </li>
                  </ul>
                  <div class="form-group mt-2" v-if="edit_interested">
                    <button class="btn btn-primary">
                      <i class="lni lni-save"></i>Save
                    </button>
                    <a
                      href="#"
                      @click.prevent="edit_interested = false"
                      class="btn btn-warning float-right"
                      >Cancel</a
                    >
                  </div>
                </section>
              </form>
            </div>
            <div class="col-md-6">
              <section class="section_mod-2">
                <h2 class="ui-title-block">WHAT THEY SAID</h2>
                <div class="ui-subtitle-block">CLIENTS TESTIMONIALS</div>
                <div class="border-color border-color_default"></div>
                <div
                  id="slider-reviews"
                  class="slider-reviews slider-reviews_mod-2"
                  v-if="!reviews_loading"
                >
                  <div class="slide mt-2" v-if="reviews.length == 0">
                    <alert type="info">There are no reviews yet.</alert>
                  </div>
                  <carousel
                    :items="1"
                    :autoplay="true"
                    v-if="reviews.length > 0"
                  >
                    <div
                      class="slide mt-2"
                      v-for="(r, i) in reviews"
                      :key="`slide-${i}`"
                    >
                      <div class="slider-reviews__quote">
                        <blockquote>
                          <p>{{ r.content }}</p>
                        </blockquote>
                        <div class="round-figure">
                          <img
                            :src="r.avatar"
                            :alt="r.name"
                            width="60"
                            height="60"
                          />
                        </div>
                        <span class="slider-reviews__autor"
                          >-- {{ r.name }}</span
                        >
                      </div>
                    </div>
                  </carousel>
                  <div v-if="reviews.length > 0">
                    <pagination
                      v-model="reviews_pagination.page"
                      :records="reviews_pagination.records"
                      @paginate="fetchReviews"
                      :perPage="5"
                    />
                  </div>
                </div>
                <loading v-if="reviews_loading" />
              </section>
            </div>
          </div>
        </div>
      </div>
    </template>

    <loading v-if="loading" />

    <modal :show="edit_intro" @close="edit_intro = false">
      <template slot="header">Edit Intro</template>
      <form @submit.prevent="updateIntro">
        <div class="form-group mt-2">
          <label>Tag Line</label>
          <input
            type="text"
            placeholder="Tag Line"
            v-model="intro.tagline"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Image</label>
          <input
            type="file"
            placeholder="Select Image"
            class="form-control"
            ref="intro_image"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">
            <i class="lni lni-save"></i>Save
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import Register from "@/components/auth/Register";

export default {
  components: {
    Register,
  },

  mounted() {
    this.fetch();

    this.fetchReviews()

    this.review = {
      name: this.user.name,
      email: this.user.email,
      phone_number: this.user.phone_number,
    };
  },

  data() {
    return {
      edit_intro: false,
      loading: true,
      intro: {},
      overview: {},
      edit_overview: false,
      interested: {},
      edit_interested: false,
      how: "",
      edit_how: false,
      listing: {},
      submitting_form: false,
      review: {},
      reviews: [],
      reviews_loading: true,
      reviews_pagination: { page: 1, records: 1 },
    };
  },

  methods: {
    fetchReviews() {
      this.reviews_loading = true;

      this.$axios
        .get(
          `/api/v1/reviews/refer-and-earn?page=${this.reviews_pagination.page}`
        )
        .then((response) => {
          this.reviews_loading = false;
          this.reviews = response.data.reviews;
        });
    },

    submitReview() {
      let data = this.review;
      data.type = "refer-and-earn";

      this.$axios.post(`/api/v1/reviews/refer-and-earn`, data).then(() => {
        this.fetchReviews();
        this.$swal("success", "Your review has been submitted.", "Success");
        document.getElementById("close-add-review-modal").click();
      });
    },

    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/content/refer-and-earn").then((response) => {
        this.intro = response.data.content.intro;
        this.overview = response.data.content.overview;
        this.interested = response.data.content.interested;
        this.how = response.data.content.how;
        this.$emit("loaded", this.overview.about);
        this.loading = false;
      });
    },

    updateIntro() {
      let data = new FormData();
      data.append("type", "refer-and-earn-intro");
      data.append("content[tagline]", this.intro.tagline);

      let image = this.$refs.intro_image.files[0];
      if (image) {
        data.append("content[image]", image);
      }

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_intro = false;
        this.fetch();
      });
    },

    updateOverview() {
      let data = {
        type: "refer-and-earn-overview",
        content: this.overview,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_overview = false;
        this.fetch();
      });
    },

    updateInterested() {
      let data = {
        type: "refer-and-earn-interested",
        content: this.interested,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_interested = false;
        this.fetch();
      });
    },

    updateHow() {
      let data = {
        type: "refer-and-earn-how",
        content: this.how,
      };

      this.$axios.post("/api/v1/admin/content", data).then(() => {
        this.edit_how = false;
        this.fetch();
      });
    },

    submit() {
      this.submitting_form = true;

      this.$axios
        .post("/api/v1/services/refer-listing", this.listing)
        .then(() => {
          this.submitting_form = false;
          this.$swal("Success", "Your listing has been received.", "success");
        })
        .catch(() => {
          this.submitting_form = false;
          this.$swal(
            "Failed",
            "This listing has already been submitted",
            "error"
          );
        });
    },

    registered() {
      window.location.reload(true);
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
