<template>
  <form @submit.prevent="register">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="form-group">
          <div class="input-with-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Full Name"
              required
              v-model="user.name"
            />
            <i class="ti-user"></i>
          </div>
          <span class="invalid-feedback d-block" v-if="errors.name">{{
            errors.name[0]
          }}</span>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="form-group">
          <div class="input-with-icon">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              required
              v-model="user.email"
            />
            <i class="ti-email"></i>
          </div>
          <span class="invalid-feedback d-block" v-if="errors.email">{{
            errors.email[0]
          }}</span>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="form-group">
          <div class="input-with-icon">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              required
              v-model="user.password"
            />
            <i class="ti-unlock"></i>
          </div>
          <span class="invalid-feedback d-block" v-if="errors.password">{{
            errors.password[0]
          }}</span>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="form-group">
          <div class="input-with-icon">
            <input
              type="password"
              class="form-control"
              placeholder="Confirm Password"
              required
              v-model="user.password_confirmation"
            />
            <i class="ti-unlock"></i>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <input
            type="checkbox"
            name="terms"
            required
            id="terms-and-conditions"
            v-model="user.terms"
          />
          <label for="terms-and-conditions" class="ms-2"
            >By clicking the buttons below, I accept the
            <a :href="terms" target="_blank" class="text-warning"
              >Terms and conditions.</a
            ></label
          >
        </div>
        <div class="invalid-feedback d-block" v-if="errors.terms">
          {{ errors.terms[0] }}
        </div>
      </div>
    </div>

    <div class="form-group text-center">
      <button
        type="submit"
        class="btn btn-md btn-theme-light-2 rounded"
        v-if="!is_loading"
      >
        Sign Up
      </button>
      <a
        href="#"
        @click.prevent=""
        v-if="is_loading"
        class="btn btn-md btn-theme-light-2 rounded"
        ><i class="lni lni-spinner"></i> Signing Up</a
      >
    </div>
  </form>
</template>

<script>
export default {
  mounted() {
    let terms = this.$store.getters.getDocuments.filter((doc) => {
      return doc.default;
    });

    if (terms) {
      this.terms = terms[0].url;
    }
  },

  data() {
    return {
      user: {},
      errors: {},
      is_loading: false,
      has_error: false,
      terms: "",
    };
  },

  methods: {
    register() {
      this.is_loading = true;
      this.has_error = false;
      let data = this.user;
      data.referral_code = this.$route.query.referral_code;

      this.$axios
        .post("/api/v1/register", data)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);

          this.$emit("success");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = {};
          }

          this.is_loading = false;
        });
    },
  },
};
</script>
